<template>
  <div>
    <div id="qrcodeImg"></div>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2';

export default {
  components: {},
  data () {
    return {
      qrcontent: 'http://192.168.200.217:1888/login?oauthToken=2',
      width: '128',
      height: '128'
    }
  },
  mounted() {
    this.qrcode();
  },
  methods: {
    qrcode() {
      new QRCode('qrcodeImg', {
        width: this.width,
        height: this.height,
        text: this.qrcontent, // 二维码地址
        colorDark: '#000',
        colorLight: '#fff'
      });
    }
  }
}
</script>

<style type="text/css" scoped>
</style>
